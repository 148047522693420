import './layout.scss';
import { createRoutesFromElements, Route, Routes } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';
import Container from 'react-bootstrap/Container';
import { ErrorBoundary } from 'react-error-boundary';
import Spinner from 'react-bootstrap/Spinner';
var Home = lazy(function () {
    return import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "homepage" */
    './home');
});
export default function Layout() {
    return (React.createElement(ErrorBoundary, { fallbackRender: ErrorFallback },
        React.createElement(Routes, null, routes)));
}
var routes = [
    React.createElement(Route, { key: "home", index: true, path: "/", element: React.createElement(Suspense, { fallback: React.createElement(Container, { as: "main" },
                React.createElement("div", { className: "text-center w-100" },
                    React.createElement(Spinner, { animation: "border" }))) },
            React.createElement(Home, null)) }),
    React.createElement(Route, { key: "404", path: "*", element: React.createElement(NotFound, null) }),
];
// used by server to check early if a route exists or not to return an early 404.
export var appRoutes = routes.map(function (r) { return createRoutesFromElements(r); }).reduce(function (a, b) { return a.concat(b); });
function NotFound() {
    return (React.createElement(Container, { className: "text-center" },
        React.createElement("h1", { className: "my-5" }, "404"),
        React.createElement("hr", null),
        React.createElement("p", { className: "mt-5" },
            React.createElement("b", null, "Page Not Found")),
        React.createElement("p", null,
            "Do you know where you're going to?",
            React.createElement("br", null),
            "Do you like the things that life is showing you?",
            React.createElement("br", null),
            "Where are you going to?",
            React.createElement("br", null),
            "Do you know",
            React.createElement("a", { className: "text-decoration-none", href: "https://www.youtube.com/watch?v=gsA-Xc6gWDE", rel: "external nofollow noopener" }, "?")),
        React.createElement("a", { className: "text-decoration-none", href: "/" }, "Home")));
}
function ErrorFallback() {
    return (React.createElement(Container, { as: "main", className: "text-center" },
        React.createElement("h1", { className: "my-5" }, "500"),
        React.createElement("hr", null),
        React.createElement("p", { className: "mt-5" },
            React.createElement("b", null, "Internal Server Error")),
        React.createElement("p", null,
            "Server has encountered an unknown error.",
            React.createElement("br", null),
            "Please reach out to the site administrator for assistance."),
        React.createElement("a", { className: "text-decoration-none", href: "/" }, "Home")));
}
