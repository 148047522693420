import React, { startTransition, StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { hydrateRoot } from 'react-dom/client';
import Layout from './layout';
function hydrate() {
    startTransition(function () {
        hydrateRoot(document.getElementById('app'), React.createElement(BrowserRouter, null,
            React.createElement(CookiesProvider, null,
                React.createElement(StrictMode, null,
                    React.createElement(Layout, null)))));
    });
}
typeof requestIdleCallback === 'function' ? requestIdleCallback(hydrate) : setTimeout(hydrate, 1);
